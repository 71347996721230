import { useTheme } from '@/store'
import { css } from '@emotion/core'
import React from 'react'

export const BrandDot: React.FC = () => {
  const theme = useTheme()

  return (
    <span
      css={css`
        color: ${theme.color.accent};
      `}
    >
      .
    </span>
  )
}
