import { useTheme } from '@/store'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React from 'react'

export const Breadcrumb: React.FC = ({ children }) => {
  const theme = useTheme()

  return (
    <nav
      aria-label="breadcrumb"
      css={css`
        max-width: ${theme.maxWidth};
        margin: 0 auto;
        margin-top: 2rem;
        padding: 0 5%;
        box-sizing: content-box;
      `}
    >
      <ol
        css={css`
          display: inline-flex;
          padding: 0.5rem 0.75rem 0.75rem;
          border: solid 1px ${theme.color.line};
          max-width: 100%;
        `}
      >
        {children}
      </ol>
    </nav>
  )
}

interface BreadcrumbItemProps {
  href?: string
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  href,
  children,
}) => {
  const theme = useTheme()

  return (
    <li
      aria-current={!href ? 'page' : undefined}
      css={css`
        color: ${theme.color.text_muted};
        font-size: ${theme.size[1]};
        white-space: nowrap;

        &:not(:first-of-type)::before {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          color: ${theme.color.text_muted};
          content: '›';
        }

        &[aria-current='page'] {
          color: ${theme.color.text_default};
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a {
          color: inherit;
          font-style: italic;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      `}
    >
      {href ? <Link to={href}>{children}</Link> : children}
    </li>
  )
}
