import { useTheme } from '@/store'
import { padding } from '@/utils/mixins'
import { css } from '@emotion/core'
import React from 'react'

type Props = {
  id: string
  topPadding?: boolean
}

export const SectionWrapper: React.FC<Props> = ({
  id,
  children,
  topPadding = true,
}) => {
  const theme = useTheme()

  return (
    <section
      id={id}
      css={css`
        ${padding};
        ${!topPadding &&
          css`
            padding-top: 0;
          `}
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidth};
          margin: 0 auto;
        `}
      >
        {children}
      </div>
    </section>
  )
}
