import { useTheme } from '@/store'
import { css } from '@emotion/core'
import React from 'react'

const hoverAnimationDuration = '0.2s'
const restAnimationDuration = '0.1s'

interface CardProps {
  zIndex: number
  radius: string
  shadowRest: string
  shadowHovered: string
}

const ShownCard: React.FC<CardProps> = ({
  zIndex,
  radius,
  shadowRest,
  shadowHovered,
  children,
}) => {
  return (
    <div
      css={css`
        position: relative;
        z-index: ${zIndex};
        border-radius: ${radius};
        overflow: hidden;
        box-shadow: ${shadowRest};

        .stacked-cards-container:hover & {
          box-shadow: ${shadowHovered};
          transition-duration: ${hoverAnimationDuration};
        }
      `}
    >
      {children}
    </div>
  )
}

interface CoveredCardProps extends CardProps {
  widthInPercent: number
  brightnessInPercent: number
}

const CoveredCard: React.FC<CoveredCardProps> = ({
  zIndex,
  radius,
  widthInPercent,
  shadowRest,
  shadowHovered,
  brightnessInPercent,
}) => {
  const theme = useTheme()

  const insetShadow = `0 8px 8px -10px rgba(${theme.color.shadow}, 0.6)}`

  return (
    <div
      css={css`
        position: relative;
        z-index: ${zIndex};
        width: ${widthInPercent}%;
        padding-top: 1.5%;
        margin: 0 auto;

        border-bottom-left-radius: ${radius};
        border-bottom-right-radius: ${radius};
        background: ${theme.color.accent};
        box-shadow: ${shadowRest}, inset ${insetShadow};
        filter: saturate(15%) brightness(${brightnessInPercent}%);

        transition: all ${restAnimationDuration} ease;

        .stacked-cards-container:hover & {
          box-shadow: ${shadowHovered}, inset ${insetShadow};
          transition-duration: ${hoverAnimationDuration};
        }
      `}
    />
  )
}

interface StackedCardProps {
  radius?: string
}

export const StackedCards: React.FC<StackedCardProps> = ({
  radius,
  children,
}) => {
  const theme = useTheme()
  const borderRadius: string = radius || theme.radius.photo

  return (
    <div
      className="stacked-cards-container"
      // css={css`
      //   transition: all ${restAnimationDuration} ease;

      //   &:hover {
      //     transform: translateY(-2px);
      //     transition-duration: ${hoverAnimationDuration};
      //   }
      // `}
    >
      <ShownCard
        zIndex={1}
        radius={borderRadius}
        shadowRest={theme.elevation[2]}
        shadowHovered={theme.elevation[3]}
      >
        {children}
      </ShownCard>

      <CoveredCard
        zIndex={2}
        radius={borderRadius}
        widthInPercent={95}
        shadowRest={theme.elevation[1]}
        shadowHovered={theme.elevation[2]}
        brightnessInPercent={130}
      />

      <CoveredCard
        zIndex={3}
        radius={borderRadius}
        widthInPercent={90}
        shadowRest={theme.elevation[0]}
        shadowHovered={theme.elevation[1]}
        brightnessInPercent={120}
      />
    </div>
  )
}
